import { filterProvider } from '../core/sdk/providers';
import { IFilter } from '../carList/carList.types';
import { getFiltersInput, IFiltersInputProps } from './filtersInput';
import { TBudgetType } from '../../interfaces/IFilters';

export class FiltersService {
    /**
     * Format response and return only required data
     * @param serverResponse
     */
    private static formatFilterResponse = (serverResponse: any) => {
        const filters: any = {};

        serverResponse._data.propertyOptions.forEach((item: any) => {
            if (item.list) {
                filters[item._name] = Array.from(item.list).map((value: any) => value[0]);
            } else {
                filters[item._name] = { min: item.min, max: item.max, average: item.average };
            }
        });

        return {
            statusCode: serverResponse._statusCode,
            items: filters,
        };
    };

    /**
     * Get List of cars from server
     * @returns Promise
     */
    public static getFilter = async (
        filters: IFilter[],
        paymentJourneyType: TBudgetType,
        nameplateBodyStyleSlug?: string
    ) => {
        const parameters: IFiltersInputProps = { paymentJourneyType, filters, nameplateBodyStyleSlug };
        try {
            const input = getFiltersInput(parameters);
            const response = await filterProvider.getInputResults(input);
            return FiltersService.formatFilterResponse(response);
        } catch (e: any) {
            throw e;
        }
    };
}
