export const BRANDID_CLIENT_ID = process.env.NEXT_PUBLIC_BRANDID_CLIENT_ID;

export const BRANDID_REGISTER_URL = process.env.NEXT_PUBLIC_BRANDID_REGISTER_URL;

export const BRANDID_LOGIN_URL = process.env.NEXT_PUBLIC_BRANDID_LOGIN_URL;

export const BRANDID_RETURN_URL = process.env.NEXT_PUBLIC_BRANDID_RETURN_URL;

export const BRANDID_RETURN_URL_CHECKOUT = process.env.NEXT_PUBLIC_BRANDID_CHECKOUT_MY_DETAILS_URL;

export const COOKIE_REDIRECT_NAME = 'BRANDID_COOKIE_REDIRECT_URL';

export const BRANDID_LOGOUT_URL = process.env.NEXT_PUBLIC_BRANDID_LOGOUT_URL;

export const SAML_LOGOUT_URL = process.env.NEXT_PUBLIC_SAML_LOGOUT_URL;

export const BRANDID_MY_ACCOUNT_URL = process.env.NEXT_PUBLIC_BRANDID_MY_ACCOUNT_URL;

export const BRANDID_REDIRECT_BOUNCE_URL = process.env.NEXT_PUBLIC_BRANDID_REDIRECT_BOUNCE;

export const BRANDID_CHECKOUT_MY_DETAILS_URL = process.env.NEXT_PUBLIC_BRANDID_CHECKOUT_MY_DETAILS_URL;

export const BRANDID_CHANGE_MAIL_URL = process.env.NEXT_PUBLIC_BRANDID_CHANGE_MAIL_URL;

export const BRANDID_OFFERS_RETURN_URL = process.env.NEXT_PUBLIC_BRANDID_OFFERS_RETURN_URL;

export const BRANDID_REGISTER_OFFERS_RETURN_URL = process.env.NEXT_PUBLIC_BRANDID_REGISTER_OFFERS_RETURN_URL;

export const SESSION_COOKIE_NAME = process.env.NEXT_PUBLIC_SESSION_COOKIE_NAME;
