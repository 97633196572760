import { createInput } from '../core/sdk/baseInput';
import { IFilter } from '../carList/carList.types';
import {
    FINANCE,
    MONTHLY_PRICES,
    LEVEL_AGGREGATION_FILTERS,
    TRANSMISSION_FIELD,
    OperatorTypes,
    TRANSMISSION_EXTRA_VALUE,
    TRANSMISSION_EXTRA_NESTING,
    BASE_PRICE,
} from './filters';
import { filterProvider } from '../core/sdk/providers';
import {
    addFilters,
    addEmployeeTypeFilter,
    addNameplateBodystyleSlugFilter,
    addWithoutOptionsFilter,
} from '../core/sdk/sdk.service';
import { TBudgetType } from '../../interfaces/IFilters';
import { PRICES, TYPE } from '../core/sdk/constants/sdkFields';

export interface IFiltersInputProps {
    paymentJourneyType: TBudgetType;
    filters: IFilter[];
    nameplateBodyStyleSlug: string;
}

export const getFiltersInput = ({ paymentJourneyType, filters, nameplateBodyStyleSlug }: IFiltersInputProps) => {
    const input: any = createInput(filterProvider);
    let paymentJourneyFilter: string = BASE_PRICE;

    if (paymentJourneyType === FINANCE) {
        paymentJourneyFilter = MONTHLY_PRICES;
    }

    const levelAggFields: string[] = LEVEL_AGGREGATION_FILTERS;
    input
        .addMinMaxAggregation(paymentJourneyFilter)
        .addFilter(
            TRANSMISSION_FIELD,
            OperatorTypes.EQUALS,
            TRANSMISSION_EXTRA_VALUE,
            TRANSMISSION_EXTRA_NESTING.split('.')
        );
    addWithoutOptionsFilter(input);
    if (nameplateBodyStyleSlug) {
        addNameplateBodystyleSlugFilter(input, nameplateBodyStyleSlug);
        input.addExtra('offerConditionTypes', ['clo']);
    }
    addEmployeeTypeFilter(input, BASE_PRICE);
    addEmployeeTypeFilter(input, `${PRICES}.${TYPE}`, BASE_PRICE);
    addFilters(filters, input);

    levelAggFields.forEach((field) => {
        input.addLevelAggregation(field);
    });

    return input;
};
