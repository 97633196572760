import { AxiosPromise } from 'axios';
import coreApiService from '../core/coreApi.service';
import { ICalculateSummaryResponse, ICalculateSummaryRequestData } from './calculateSummary.types';
import { BRAND, LANGUAGE, SESSION_REQUEST_HEADER_NAME } from '../../constants/main';
import { mapBrandStringToNumericCode } from '@utils/appConfig.utils';

const FINANCE_CALCULATOR_SERVICE_URL = 'fc/';
const ENDPOINT_BASE: string = `${FINANCE_CALCULATOR_SERVICE_URL}api/v3/${mapBrandStringToNumericCode(
    BRAND
)}/${LANGUAGE}`;

export const getCalculateSummary = (data: ICalculateSummaryRequestData): AxiosPromise<ICalculateSummaryResponse> => {
    return new Promise((resolve, reject) => {
        coreApiService.post(`${ENDPOINT_BASE}/calculate-for-summary`, data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const getSummaryOfFQ = (fqToken: string, token?: string): AxiosPromise<ICalculateSummaryResponse> => {
    const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
    return coreApiService.get(`${ENDPOINT_BASE}/summary/${fqToken}`, null, headers);
};
