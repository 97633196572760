import { IVehicleTransformed } from '../stock/models/stockItems.service.model';
import { IOfferItem } from '../../interfaces/IOfferItem';
import { IFinanceWidgetParameters } from '../../redux/financeWidget/financeWidget.duck.interface';

export interface ICalculateSummaryRequestData {
    carConfigId: string;
    carConfiguration?: IVehicleTransformed | IOfferItem;
    financeProductKey?: string;
    deposit?: number;
    duration?: number;
    annualMileage?: number;
    currency?: string;
    vehiclePrice?: number;
    token?: string;
    financeGatewayParameters?: IFinanceWidgetParameters;
    isPersonalized?: boolean;
    providerType?: string;
}

export interface LegalNotice {
    order: number;
    text: string;
}

export interface LegalMention {
    order: number;
    text: string;
}

export interface LegalText {
    order: number;
    text: string;
}

export interface FinancialProductLabel {
    text: string;
}

export interface DetailInformationLink {
    text: string;
    href: string;
}

export interface ICallToActionGlobalTexts {
    detailText: string;
    text: string;
}

export interface GlobalTexts {
    legalNotice: LegalNotice[];
    legalMention: LegalMention[];
    financialProductLabel: FinancialProductLabel;
    detailTitle: string;
    detailSubTitle: string;
    detailInformationLink: DetailInformationLink;
    callToAction?: ICallToActionGlobalTexts;
}

export enum EProviderType {
    LEASYS = 'externalleasysfinancialgateway',
    FINANCE = 'financialgateway',
}

export interface IFinanceQuote {
    providerType?: EProviderType;
    depositAmountKind?: 'MT' | 'PCT';
    token?: string;
    financeProductKey?: string;
    financePackageKey?: string;
    financialType?: string;
    serviceCode?: any;
    packageCode?: any;
    financeProductName?: string;
    isPersonalized?: boolean;
    noDeposit?: any;
    deposit: number;
    depositWithVat?: number;
    quotationId?: string;
    annualMileage: number;
    globalMileage: number;
    duration: number;
    durationMetrics?: any;
    pxId?: any;
    pxDeposit?: any;
    vehiclePrice?: number;
    vehicleReducedPrice?: any;
    employeeDiscount?: any;
    extraDiscount?: any;
    totalDiscount?: any;
    totalDeposit?: number;
    balanceLeftToFinance?: any;
    optionalFinalPaymentToBuy?: any;
    apr?: number;
    tin?: number;
    numberOfMonthlyPayments: number;
    monthlyPayment: number;
    excessMileageCharges?: any;
    leadTimeDate?: any;
    leadTime?: any;
    interestRate?: any;
    interestPayable?: any;
    creditFacilityFee?: any;
    totalAmountFinanced?: any;
    firstRental?: number;
    currency?: string;
    contributions?: any[];
    blocks?: IBlock[];
    options?: any[];
    legalTexts?: ILegalText[];
    createdAt?: any;
    updatedAt?: any;
    expiresAt?: any;
    textConditions?: any[];
    primaryText?: any;
    additionnalPrecision?: IAdditionnalPrecision;
    globalTexts?: GlobalTexts;
    financeGatewayParameters?: IFinanceWidgetParameters;
    originalDeposit?: number;
    finalPayment?: number;
}

export type ICalculateSummaryResponse = IFinanceQuote;

export interface IStyleType {
    bold: boolean;
    displaySize: number;
    indent?: boolean;
}

export interface IDisplayLine {
    order: number;
    label: string;
    displayValue: string;
    value: string;
    hasNotification: boolean;
    styleType: IStyleType;
    isPrice: boolean;
}

export interface IBlock {
    order: number;
    displayLines: IDisplayLine[];
}

export interface ILegalText {
    order: number;
    text: string;
    style?: {
        displaySize: number;
        indent: boolean;
    };
}

export interface IAdditionnalPrecision {
    primaryText: string;
    secondaryText: string;
    thirdText: string;
}
